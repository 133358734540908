<template>
	<!-- 课程详情 -->
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="page-bread">
			<el-breadcrumb-item><span @click="tohome">首页</span></el-breadcrumb-item>
			<el-breadcrumb-item v-for="(item,index) in catClass" :key="index">
				<span @click="tbchoose(item.cat_id,0)">{{item.name}}</span>
			</el-breadcrumb-item>
		</el-breadcrumb>
		<contact ref='child'></contact>
		<!-- 遮罩 -->
		<div v-show="this.zhezhao==true">
			<div class="zhezhao"></div>
			<div class="successBox">
				<div class="boxTips">
					<img src="../../../assets/img/courseDetail/success.png" style="float: left;">
					<span>报名成功</span>
				</div>
				<div class="boxText">恭喜你，报名成功，赶快去学习吧</div>
				<div class="boxBtn" @click="toLearn1()">去学习</div>
			</div>
		</div>

		<!-- 上部分 -->
		<!-- 付费内容 -->
		<div>
			<div class="page-all">
				<img :src="goodsData.goods_img" class="coursePic">
				<div class="rightTitle">
					<span>{{goodsData.goods_name}}</span>
					<div class="titleRight">
						<img @click="showchat" src="../../../assets/img/courseDetail/chat.png" class="styletwo">
						<img src="../../../assets/img/courseDetail/share.png" class="styletwo mristyle" @mouseenter="creatQrCode"
						 @mouseleave="share=false">
						<img src="../../../assets/img/courseDetail/collect.png" class="styletwo" v-if="this.is_collection==0" @click="toCollect()">
						<img src="../../../assets/img/courseDetail/collect1.png" class="styletwo" v-if="this.is_collection==1" @click="toCollect()">
					</div>
					<!-- 分享按钮 -->
					<div v-show="this.share==true">
						<div class="shareBox">
							<div class="shareBoxItem">
								<img src="../../../assets/img/courseDetail/qq.png" class="plateformIcon">
								<div ref="qrCodeUrl" id="qrCode" class="erweimaIcon"></div>
								<div class="shareBoxText">QQ扫码</div>
							</div>
							<div class="shareBoxItem">
								<img src="../../../assets/img/courseDetail/wechat.png" class="plateformIcon">
								<div ref="vrCodeUrl" id="qrCode" class="erweimaIcon"></div>
								<div class="shareBoxText">微信扫码</div>
							</div>
							<div class="shareBoxItem">
								<img src="../../../assets/img/courseDetail/sina.png" class="plateformIcon">
								<div ref="wrCodeUrl" id="qrCode" class="erweimaIcon"></div>
								<div class="shareBoxText">微博扫码</div>
							</div>
						</div>
						<div class="triangle"></div>
					</div>
					<!-- 咨询按钮 -->

				</div>
				<div class="rightGrade">
					<span class="gradeText">报名人数 {{goodsData.countsignup}}</span>
					<el-divider direction="vertical"></el-divider>
					<!-- <span class="colLine1">|</span> -->
					<el-rate class="rate" v-model="goodsData.rank" disabled show-score text-color="#ff9900" score-template="{value}"></el-rate>
				</div>
				<div class="rightPrice">
					<span>价格：</span>
					<span>￥</span>
					<span>{{goodsData.goods_price}}</span>
				</div>
				<div class="rightDiscount">
					<div>活动</div>
					<div @click="getDiscount()">领取优惠券</div>
				</div>
				<!-- 优惠券窗口 -->
				<div class="organizeDiscount" v-show="this.discount==true">
					<!-- 机构优惠券 -->
					<div class="discountTitle">限时优惠，可领取该机构课程的优惠券</div>
					<div v-for="item in couponData" v-show="item.school_id==3">
						<div class="line"></div>
						<div class="discountContent">
							<div class="contentUp">
								<span>￥{{item.discount}}</span>
								<span>{{item.coupon_name}}</span>
								<span v-show="item.is_get==0" @click="toGetCoupon(item.id)">领取</span>
								<span v-show="item.is_get==1">已领取</span>
							</div>
							<div class="contentDown">
								有效期至：{{item.end_time}}
							</div>
						</div>
					</div>
					<!-- 平台优惠券 -->
					<div class="discountTitle">限时优惠，可领取该平台课程的优惠券</div>
					<div v-for="item in couponData" v-show="item.school_id==0">
						<div class="line"></div>
						<div class="discountContent">
							<div class="contentUp">
								<span>￥{{item.discount}}</span>
								<span>{{item.coupon_name}}</span>
								<span v-show="item.is_get==0" @click="toGetCoupon(item.id)">领取</span>
								<span v-show="item.is_get==1">已领取</span>
							</div>
							<div class="contentDown">
								有效期至：{{item.end_time}}
							</div>
						</div>
					</div>


				</div>
				<div class="rightBtn">
					<div class="rightBtn1" @click="toLearn()" v-show="this.is_show==0">立即报名</div>
					<div class="rightBtn2" v-show="this.is_show==1">
						<div>报名成功</div>
						<div class="zhezhao3"></div>
					</div>
					<a href="#mulu">
						<div class="rightBtn3" @click="preWatch()">试看</div>
					</a>
					<!-- 	<div class="rightBtn4">
						<img src="../../../assets/img/courseDetail/buyCar.png" class="buyCarIcon">
						<span @click="toAdd()">购物车</span>
					</div> -->
				</div>
			</div>
		</div>
		<!-- 免费 -->
		<div v-show="this.goodsData.is_free==1">
			<div class="page-all1">
				<div class="allLeft">
					<!-- 播放器遮罩 -->
					<div v-show="this.is_show==0">
						<div class="zhezhao4"></div>
					</div>
					<div class="video-out">
						<video width="100%" :src="url" height="100%" controls>
						</video>
					</div>
					<div class="allLeftIntro">
						<div class="introLeft">
							<div class="title">
								<span>{{goodsData.goods_name}}</span>
								<span>免费</span>
							</div>
							<div class="info">
								<span class="infoPeople">报名人数 {{goodsData.countsignup}}</span>
								<span class="colLine">|</span>
								<el-rate class="rate mcimr34" v-model="goodsData.rank" disabled show-score text-color="#ff9900" score-template="{value}"></el-rate>
								<img src="../../../assets/img/courseDetail/chat.png" class="icon">
								<img src="../../../assets/img/courseDetail/share.png" class="icon" @mouseenter="share=true" @mouseleave="share=false">
								<img src="../../../assets/img/courseDetail/collect.png" class="icon" v-if="this.is_collection==0" @click="toCollect()">
								<img src="../../../assets/img/courseDetail/collect1.png" class="icon" v-if="this.is_collection==1" @click="toCollect()">
								<img src="../../../assets/img/courseDetail/phone.png" class="icon mci4">
								<span class="infoPeople" @click="toPhone()">用手机看</span>
							</div>
						</div>
						<!-- 报名按钮 -->
						<div class="introRight" v-show="this.is_show==0" @click="toLearn()">免费报名</div>
						<div v-show="this.is_show==1">
							<div class="introRight">报名成功</div>
							<div class="zhezhao2"></div>
						</div>
						<!-- 分享按钮 -->
						<div v-show="this.share==true">
							<div class="shareBox1">
								<div class="shareBoxItem">
									<img src="../../../assets/img/courseDetail/qq.png" class="plateformIcon">
									<div ref="q1rCodeUrl" id="qrCode" class="erweimaIcon"></div>
									<div class="shareBoxText">QQ扫码</div>
								</div>
								<div class="shareBoxItem">
									<img src="../../../assets/img/courseDetail/wechat.png" class="plateformIcon">
									<div ref="v1rCodeUrl" id="qrCode" class="erweimaIcon"></div>
									<div class="shareBoxText">微信扫码</div>
								</div>
								<div class="shareBoxItem">
									<img src="../../../assets/img/courseDetail/sina.png" class="plateformIcon">
									<div ref="w1rCodeUrl" id="qrCode" class="erweimaIcon"></div>
									<div class="shareBoxText">微博扫码</div>
								</div>
							</div>
							<div class="triangle1"></div>
						</div>
						<!-- 用手机看 -->
						<div v-show="this.phone==true">
							<div class="phoneBox">
								<div class="phoneBoxText">扫一扫继续用手机看</div>
								<div class="phoneBoxCenter">
									<div class="phoneBoxItem">
										<img src="../../../assets/img/courseDetail/erweima.png">
										<div class="itemText">
											<img src="../../../assets/img/courseDetail/wx.png" class="d12">
											<span>微信扫码</span>
										</div>
									</div>
									<div class="diviver"></div>
									<div class="phoneBoxItem">
										<img src="../../../assets/img/courseDetail/erweima.png">
										<div class="itemText">
											<img src="../../../assets/img/courseDetail/qq1.png" class="d12">
											<span>qq扫码</span>
										</div>
									</div>
								</div>
								<div class="phoneBoxBtn">下载手机app</div>
							</div>
							<div class="triangle2"></div>
						</div>

					</div>
				</div>
				<div class="allRight">
					<div class="allRightBox">
						<el-collapse v-model="activeNames" @change="handleChange" class="detailTabs">
							<el-collapse-item class="collapseItem" v-for="(item,index) in menuData" :title="item.name" :name="item.id">
								<div v-for="(i,index) in item.child">
									<div class="tabsItem" @click="toWactch(i.id)">
										<div class="videoIcon">
											<img src="../../../assets/img/courseDetail/video.png">
										</div>
										<div class="tabsTitle">{{i.name}}</div>
										<div class="tabsContent1">
											<span>【录播】</span>
											{{i.live_time}}
										</div>
									</div>
									<div class="line1" v-if="index!=(item.child.length-1)"></div>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
			</div>
		</div>

		<div class="micro-center">
			<div>
				<div class="micro-title">{{goodsData.goods_name}}</div>
				<img class="micro-img" :src="goodsData.goods_img">
				</img>
			</div>
			<div>
				<p class="micro-p1">{{goodsData.goods_name}}</p>
				<p class="micro-p2">直播时间：{{goodsData.start_time}}</p>
				<p class="micro-p2">讲师：Jack 特邀自由插画师</p>
			</div>
			<div class="solute">
				<div class="micro-button">
					预约
				</div>
			</div>
		</div>
		<div style="width: 95%;margin: 0 auto;">
			<div class="p-home-room">
				<div class="fex-speen-aroud">
					<img class="p-mic-room-about-img-left mcri-50px" @click="toAheadItem" src="../../../assets/img/home/right.png" />
					<img @click="toNextItem" class="p-mic-room-about-img-right mcri-50rpx" src="../../../assets/img/home/left.png" />
				</div>

				<div class="p-home-room-about-b" id="scollItems">
					<div class="p-home-vip" v-for="(item,index) in goodsData.goodslist" :key="index" @click="todetail(item.id)">
						<img :src="item.goods_img" class="p-home-vip-image" />
						<div>{{item.goods_name}}</div>
						<!-- 						<div>111</div>
						<div><span>111人在学</span></div> -->
					</div>
				</div>

			</div>
		</div>
		<!-- 下部分 -->

		<div class="page-content">
			<!-- 课程详细介绍 -->
			<div class="detail-menu">
				<!-- 菜单 -->
				<div v-for="(item,index) in menuList" :class="['menuTitle',chooseMenu==item.id?'menuTitleIn':'']" @click="courseMenu(item.id)">{{item.name}}</div>
			</div>
			<div class="page-detail">
				<!-- 课程简介 -->
				<div class="detailTitle">
					<div class="micro-bang"></div>
					<div class="micro-mrbang" id="1">关于课程</div>
					<div class="micro-bang"></div>
				</div>
				<img v-for="(item,index) in goodsData.comment_img" :key="index" src="../../../assets/img/courseDetail/organizeBg.png" />
				<!-- 课程评价 -->
				<div class="detailTitle">
					<div class="micro-bang"></div>
					<div class="micro-mrbang" id="2">学过的人这么说</div>
					<div class="micro-bang"></div>
				</div>
				<img v-for="(item,index) in goodsData.comment_img" :key="index" src="../../../assets/img/courseDetail/organizeBg.png" />
				<!-- 答疑解惑 -->
				<div class="detailTitle">
					<div class="micro-bang"></div>
					<div class="micro-mrbang" id="3">答疑解惑</div>
					<div class="micro-bang"></div>
				</div>
				<img v-for="(item,index) in goodsData.comment_img" :key="index" src="../../../assets/img/courseDetail/organizeBg.png" />
				<!-- 				<div class="micro-white">
					<div class="micro-one" v-for="(item,index) in 3" :key="index">
						<div class="micro-white-one">
							<div>Q1</div>
							<div>学习这个课程需要什么基础？</div>
						</div>
						<div class="micro-white-two">了解Java基础(集合类、I0操作、异常处理、并发原.语、序列化等)2) 数据库基础(常用的增删改查,能够足日常工作)。3)会用常用的框架与工具，如spring框架、javaweb容器(tomcat、 jetty)、
							代码管理工具</div>
					</div>
				</div> -->
			</div>
		</div>
		<totop></totop>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	import contact from '../../common/contact.vue';
	import totop from '../../common/totop.vue';
	export default {
		name: "qrCode",
		components: {
			contact,
			totop
		},
		data() {
			return {
				// 遮罩
				zhezhao: false,
				free: 1,
				// 课程评分
				value: 3.7,
				// 优惠券
				discount: false,
				couponData: {},
				schoolId: '',
				catClass: [],
				// 菜单
				chooseMenu: 1,
				menuList: [{
						id: 1,
						name: "课程简介"
					},
					{
						id: 2,
						name: "课程评价"
					},
					{
						id: 3,
						name: "答疑解惑"
					},
				],
				// 课程目录折叠面板
				activeNames: [1],
				// 单选按钮组
				radio: 0,
				Inv: 0,
				// 右上角分享按钮
				share: false,
				// 右上角收按钮
				is_collection: 0,
				collect: 0,
				// 获取到的id
				resId: 7,
				// 课程数据
				goodsData: {},
				// 机构数据
				schoolData: {},
				// 课程目录数据
				menuData: {},
				// 课程评价数据
				commentData: {},
				advRank: '',
				size: 0,
				// 分页
				list_rows: 1,
				page: 1,
				currentPage: 1,
				total: 0,
				// 该课程是否报名
				learn: false,
				// 用手机看按钮
				phone: false,
				// 判断用户类型
				type: 1,
				// 判断是否报名
				is_show: 0,
				// 播放视频的url
				url: '',
				// type为3时获取到的数据
				resData: {},

				qcode: 1,

			}
		},
		created() {
			this.getData(this.$route.query.id)
		},
		mounted() {},
		beforeDestroy() {
			this.qcode = 1
		},
		watch: {
			'$route': function() {
				this.getData(this.$route.query.id)
			},
		},
		methods: {
			// 判断是否报名成功（视频是否可播放）
			checkPay() {
				this.$post('goods/check', {
					goods_id: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.is_show = response.is_show
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			toAheadItem() {
				document.getElementById('scollItems').scrollLeft -= 1000;
			},
			toNextItem() {
				document.getElementById('scollItems').scrollLeft += 1000;
			},
			//点击头部跳转到分类
			tbchoose(e, index) {
				this.bchoose = e
				this.$router.push({
					path: '/classTop',
					query: {
						id: e,
						data: this.chooselist,
						choose: index,
					}
				})
			},
			//生成二维码
			creatQrCode() {
				this.share = true
				if (this.qcode == 1) {
					var qrcode = new QRCode(this.$refs.qrCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var qrcode = new QRCode(this.$refs.q1rCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var brcode = new QRCode(this.$refs.vrCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var brcode = new QRCode(this.$refs.v1rCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var cqcode = new QRCode(this.$refs.wrCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var cqcode = new QRCode(this.$refs.w1rCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
				}
				this.qcode = 2
			},
			// 获取课程总数据
			getData(e) {
				this.resId = e
				scrollTo(0, 0);
				//this.checkPay()
				// /this.getMenuList()
				//this.checkCollection()
				this.$get('microgoods/index/' + e).then(response => {
					if (response.error == 0) {
						this.goodsData = response.data.goods
						this.schoolData = response.data.school;
						this.catClass = response.data.cat
						this.schoolId = this.schoolData.id
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 课程目录接口
			getMenuList() {
				this.$post('goods/content/', {
					goods_id: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.menuData = response.data
					} else {
						this.$message.error(response.msg);
					}
				});
			},


			// 获取优惠券列表
			getCoupon() {
				// 需要schoolid
				this.$get('goods/getcoupon/' + this.schoolId).then(response => {
					if (response.error == 0) {
						this.couponData = response.data;
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 领取优惠券
			toGetCoupon(which) {
				this.$post('goods/addcoupon/', {
					// school_id: this.schoolId,
					coupon_id: which
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg);
						this.getCoupon()
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 优惠券按钮
			getDiscount() {
				this.getCoupon()
				if (this.discount == true) {
					this.discount = false
				} else {
					this.discount = true
				}
			},

			// 校验用户是否收藏此课程
			checkCollection() {
				this.$post('goods/checkcollection/', {
					goods_id: this.resId,
				}).then(response => {
					if (response.error == 0) {
						this.is_collection = response.is_collection
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 收藏按钮
			toCollect() {
				if (this.is_collection == 0) {
					this.$post('goods/collectgoods/', {
						goods_id: this.resId,
						status: 1
					}).then(response => {
						if (response.error == 0) {
							this.is_collection = 1
							this.$message.success(response.msg);
						} else {
							this.$message.error(response.msg);
						}
					});
				} else if (this.is_collection == 1) {
					this.$post('goods/collectgoods/', {
						goods_id: this.resId,
						status: 2
					}).then(response => {
						if (response.error == 0) {
							this.is_collection = 0
							this.$message.success(response.msg);
						} else {
							this.$message.error(response.msg);
						}
					});
				}
			},

			// 添加购物车
			toAdd() {
				this.$post('cart/addcart/', {
					goods_id: this.resId,
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg);
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 报名操作接口
			payAdd() {
				this.$post('order/checkoutmicro/', {
					micro_gid: [this.resId],
				}).then(response => {
					if (response.error == 0) {
						this.type = response.type
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 报名按钮
			toLearn() {
				this.$post('order/checkoutmicro/', {
					micro_gid: [this.resId]
				}).then(response => {
					if (response.error == 0) {
						this.type = response.type
						// 判断是否免费
						if (this.type == 1) {
							this.zhezhao = true
						} else if (this.type == 2) {
							this.goodsData.is_free = 1
							this.type == 1
						} else if (this.type == 3) {
							this.resData = response.data
							this.$router.push({
								path: '/payConfirm',
								query: {
									id: this.resId,
									data: this.resData,
									ismcro: 1
								}
							})
						}
						this.checkPay()
						this.$message.success(response.msg);
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			// 去学习
			toLearn1() {
				this.zhezhao = false
				this.is_show = 1
			},

			// 课程章节播放接口
			toWactch(id) {
				this.$post('order/checkoutmicro/', {
					micro_gid: [this.resId]
				}).then(response => {
					if (response.error == 0) {
						this.type = response.type
						// 免费页面播放
						this.$post('goods/look/', {
							goods_id: this.resId,
							content_id: id
						}).then(response => {
							if (response.error == 0) {
								this.url = response.data.content.url
								if (this.is_show == 0) {
									this.$message.error("请先报名！");
								} else {
									if (this.type == 2 || this.type == 3) {
										this.goodsData.is_free = 1
									}
									this.$message.success("播放成功！");
								}
							} else {
								this.$message.error(response.msg);
							}
						});
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 选择菜单
			courseMenu(which) {
				this.chooseMenu = which
				var oDiv = document.getElementById(which);
				var t = document.createElement("input");
				t.type = "text";
				oDiv.insertBefore(t, null);
				t.focus();
				oDiv.removeChild(t);
			},

			// 选择评价类型
			chooseComment() {
				this.getCommentList()
			},

			// 试看
			preWatch() {
				this.chooseMenu = 2
			},

			// 向x轴右方向滚动
			toNextItem() {
				document.getElementById('scollItems').scrollLeft += 1000;
			},

			// 向x轴左方向滚动
			toAheadItem() {
				document.getElementById('scollItems').scrollLeft -= 1000;
			},

			// 课程目录折叠面板
			handleChange(val) {},

			// 跳转到机构主页
			toOrganize() {
				this.$router.push({
					path: '/organizeInfo',
					query: {
						id: this.schoolId
					}
				})
			},
			tohome() {
				this.$router.push({
					path: '/home',
					query: {
						id: this.schoolId
					}
				})
			},
			// 其他课程显示更多
			showMore() {
				if (this.Inv == 0) {
					this.Inv = 1
				}
			},
			// 分享按钮
			toShare() {
				if (this.share == false) {
					this.share = true
				} else {
					this.share = false
				}
			},

			// 用手机看
			toPhone() {
				if (this.phone == false) {
					this.phone = true
				} else {
					this.phone = false
				}
			},

			// 当前页的变化
			handleCurrentChange(e) {
				this.page = e
				this.getCommentList()
			},
			showchat() {
				this.$refs.child.toshow(this.schoolId)
			}
		},
	}
</script>

<style scoped>
	.page-bread {
		position: relative;
		top: 33px;
		margin-bottom: 63px;
		margin-left: 12%;
	}

	.zhezhao {
		width: 100%;
		height: 3100px;
		opacity: 0.6;
		background: #000000;
		position: absolute;
		z-index: 2;
		margin-top: -100px;
	}

	/* 微专业 */
	.micro-center {
		width: 76%;
		height: 262px;
		background: #FFFFFF;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		border-radius: 15px;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
	}

	.micro-title {
		margin-left: 40px;
		margin-top: 30px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.micro-img {
		width: 237px;
		height: 157px;
		border-radius: 15px;
		margin-left: 15px;
		margin-top: 19px;
	}

	.micro-p1 {
		margin-top: 76px;
		margin-left: 37px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.micro-p2 {
		margin-top: 24px;
		margin-left: 37px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;

	}

	.solute {
		width: 76%;
		margin-top: 100px;
		position: absolute;
		left: 0;
		margin-left: 12%;
	}

	.micro-button {
		width: 95px;
		height: 41px;
		background: #00D0B0;
		border-radius: 21px;
		text-align: center;
		line-height: 41px;
		float: right;
		font-size: 14px;
		cursor: pointer;
		margin-right: 70px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
	}

	.successBox {
		width: 460px;
		height: 300px;
		background: #FFFFFF;
		position: absolute;
		z-index: 3;
		margin-top: 150px;
		margin-left: 35%;
		text-align: center;
	}

	.boxTips {
		width: 140px;
		heigh: auto;
		margin: 0 auto;
		margin-top: 60px;
		border: 1px solid transparent;
	}

	.boxTips span {
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 35px;
		margin-left: 16px;
	}

	.boxText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 38px;
	}

	.boxBtn {
		width: 355px;
		height: 68px;
		background: #00D0B0;
		border-radius: 10px;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 68px;
		text-align: center;
		margin: 0 auto;
		margin-top: 50px;
	}

	.page-all {
		width: 74.5%;
		height: 280px;
		background-color: white;
		margin: 0 auto;
		padding: 20px 20px;
	}

	.page-content {
		width: 76%;
		height: 2000px;
		margin: 0 auto;
		margin-top: 20px;
	}

	.page-detail {
		width: 100%;
		height: 1800px;
		margin-right: 1.5%;
		float: left;
		padding-bottom: 60px;
		overflow: hidden;
		overflow-y: auto;
	}

	.page-detail img {
		width: 100%;
	}

	.coursePic {
		width: 470px;
		height: 280px;
		background-color: #000000;
		float: left;
		margin-right: 25px;
	}

	.rightTitle {
		font-size: 24px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}
	.rightTitle img{
		cursor: pointer;
	}
	.titleRight {
		height: 32px;
		float: right;
	}

	.shareBox {
		width: 342px;
		height: 269px;
		background: #FFFFFF;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		position: absolute;
		margin-left: 1100px;
		display: flex;
		flex: 3;
		flex-wrap: nowrap;
	}

	.shareBoxItem {
		width: 114px;
		height: 269px;
		text-align: center;
	}

	.shareBoxText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 17px;
	}

	.plateformIcon {
		margin-top: 36px;
		height: 51px;
		width: 51px;
	}

	.erweimaIcon {
		margin-top: 21px;
		height: 90px;
		width: 91px;
		margin-left: 8px;
	}

	.triangle {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		margin-left: 1170px;
		margin-top: -5px;
	}

	.rightGrade {
		width: auto;
		height: 26px;
		margin-top: 20px;
	}

	.gradeText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 26px;
		margin-right: 20px;
	}

	.colLine1 {
		font-size: 14px;
		background: #E6E6E6;
		line-height: 26px;
	}

	.line {
		width: 100%;
		height: 1px;
		background-color: #E6E6E6;
		margin-bottom: 20px;
	}

	.line1 {
		width: 95%;
		height: 1px;
		background-color: #E6E6E6;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.rate {
		display: inline-block;
		margin-left: 20px;
		line-height: 12px;
	}

	.rightPrice {
		width: auto;
		height: 34px;
		margin: 20px 0;
	}

	.rightPrice span:nth-child(1) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.rightPrice span:nth-child(2) {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
	}

	.rightPrice span:nth-child(3) {
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-right: 20px;
	}

	.rightPrice span:nth-child(4) {
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-right: 20px;
	}

	.rightPrice span:nth-child(5) {
		display: inline-block;
		width: 80px;
		height: 30px;
		background: #E0BD59;
		border-radius: 5px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
	}

	.rightDiscount div:first-child {
		display: inline-block;
		width: 40px;
		height: 24px;
		border: 1px solid #56D2BF;
		border-radius: 5px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #56D2BF;
		cursor: pointer;
		line-height: 24px;
		text-align: center;
		margin-right: 8px;
	}

	.rightDiscount div:last-child {
		display: inline-block;
		width: 76px;
		height: 24px;
		border: 1px solid #F4586C;
		border-radius: 5px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		line-height: 24px;
		cursor: pointer;
		text-align: center;
	}

	/* 优惠券窗口 */
	.organizeDiscount {
		width: 250px;
		height: 186px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		position: absolute;
		margin-left: 500px;
		z-index: 1;
		overflow-y: auto;
		text-align: left;
	}

	.discountTitle {
		width: 212px;
		height: 35px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		margin: 10px;
	}

	.discountContent {
		width: 212px;
		height: auto;
		margin: 10px;
	}

	.contentUp {
		width: 100%;
		height: 30px;
	}

	.contentUp span:nth-child(1) {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #F4586C;
	}

	.contentUp span:nth-child(2) {
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.contentUp span:nth-child(3) {
		width: 46px;
		height: 20px;
		background: #FFFFFF;
		border: 1px solid #F4586C;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		line-height: 20px;
		text-align: center;
		float: right;
	}

	.contentUp span:nth-child(4) {
		width: 46px;
		height: 20px;
		background: #FFFFFF;
		border: 1px solid #999999;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
		text-align: center;
		float: right;
	}

	.contentDown {
		font-size: 13px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.rightBtn1 {
		display: inline-block;
		width: 118px;
		height: 44px;
		background: #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		cursor: pointer;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		margin-top: 60px;
	}

	.rightBtn2 {
		display: inline-block;
		width: 118px;
		height: 44px;
		background: #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		text-align: center;
		margin-top: 60px;
	}

	.rightBtn3 {
		display: inline-block;
		width: 118px;
		height: 44px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		line-height: 44px;
		text-align: center;
		margin: 0 13px;
	}

	.rightBtn3:active {
		background-color: #00D0B0;
		color: white;
	}

	.rightBtn4 {
		display: inline-block;
		width: 118px;
		height: 44px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		line-height: 44px;
		text-align: center;
	}

	.rightBtn4:active {
		background-color: #00D0B0;
		color: white;
	}

	.zhezhao3 {
		width: 118px;
		height: 44px;
		background: #000000;
		opacity: 0.1;
		position: absolute;
		z-index: 1;
		margin-top: -44px;
	}

	.detail-menu {
		width: 100%;
		height: 54px;
		background: #FFFFFF;
		display: flex;
		flex-wrap: nowrap;
	}

	.menuTitle {
		width: 150px;
		height: 54px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		cursor: pointer;
		line-height: 54px;
		text-align: center;
	}

	.menuTitleIn {
		width: 150px;
		height: 54px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: white;
		background-color: #00D0B0;
		line-height: 54px;
		text-align: center;
	}

	.detailTitle {
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
		margin-left: 20px;
		height: 141px;
		display: flex;
		justify-content: center;
		align-items: center;
		line-height: 141px;
		margin-bottom: 20px;
		font-size: 42px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #7B7B7B;
		text-align: center;
	}

	.micro-white {
		background: white;
		height: auto;
	}

	.micro-one {
		height: 365px;
		width: 100%;
		border-bottom: 1px solid #DDDDDD;
	}

	.micro-white-one {
		padding-top: 70px;
		width: 100%;
		margin-bottom: 47px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.micro-white-one :nth-child(1) {
		width: 44px;
		height: 32px;
		background: #00D0B0;
		border-radius: 5px;
		color: white;
		margin-left: 62px;
		text-align: center;
		line-height: 32px;

	}

	.micro-white-one :nth-child(2) {
		height: 32px;
		border-radius: 5px;
		color: #333333;
		margin-left: 20px;
		line-height: 32px;

	}

	.micro-white-two {
		width: 80%;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin: 0 auto;
	}

	.micro-bang {
		width: 30px;
		height: 6px;
		background: #56D2BF;
		border-radius: 3px;
	}

	.micro-mrbang {
		margin-left: 25px;
		margin-right: 25px;
	}

	.detailTabs {
		margin-top: 20px;
	}

	.tabsItem {
		width: 90%;
		height: 50px;
		margin-left: 60px;
	}

	.videoIcon {
		width: 30px;
		height: 40px;
		float: left;
		margin-top: 5px;
	}

	.tabsTitle {
		display: inline-block;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-right: 10px;
	}

	.tabsContent {
		display: inline-block;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}


	.itemRight {
		width: 80%;
		height: 90px;
		display: inline-block;
	}

	.itemRight div:nth-child(1) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #ACACAC;
		float: right;
	}

	.itemRight div:nth-child(2) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.itemRight div:nth-child(3) {
		width: 600px;
		height: 40px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		overflow: hidden;
		margin-top: 5px;
	}

	.itemRight div:nth-child(4) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.colLine {
		width: 1px;
		height: 46px;
		background: #E4E4E4;
		color: #E4E4E4;
		margin: 0 22px;
	}

	.organizeInfo {
		width: 80%;
		height: 60px;
		margin: 0 auto;
		margin-top: 23px;
		margin-bottom: 18px;
		display: flex;
		flex-wrap: nowrap;
		flex: 3;
	}

	.infoItem {
		width: auto;
		height: 60px;
		text-align: center;
	}

	.infoItem div:first-child {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.infoItem div:last-child {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-top: 15px;
	}

	.mr {
		margin: 0 40px;
	}


	.otherCourseText {
		display: inline-block;
		width: 65%;
		height: auto;
	}

	.otherCourseText div:nth-child(1) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-top: 6px;
	}

	.otherCourseText div:nth-child(2) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-top: 13px;
	}

	.otherCourseText div:nth-child(3) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-top: 13px;
	}


	/* 免费课程内容 */
	.page-all1 {
		width: 75%;
		height: 580px;
		background-color: white;
		margin: 0 auto;
		display: flex;
		flex: 2;
		flex-wrap: nowrap;
	}

	.allLeft {
		width: 70%;
		height: 580px;
		background: #333333;
	}

	.zhezhao4 {
		width: 52%;
		height: 490px;
		opacity: 0.6;
		background: #000000;
		position: absolute;
		z-index: 2;
	}

	.video-out {
		width: 100%;
		height: 494px;
	}

	.allLeftIntro {
		width: 95%;
		height: 60px;
		margin: 0 auto;
		margin-top: 12px;
		display: flex;
		flex: 2;
		flex-wrap: nowrap;
	}

	.introLeft {
		width: 650px;
		height: 55px;
	}

	.introLeft .title {
		font-size: 24px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
	}

	.introLeft .title span:last-child {
		color: #F83850;
		margin-left: 30px;
	}

	.infoPeople {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 36px;
	}

	.rate {
		display: inline-block;
	}

	.icon {
		width: 12px;
		height: 12px;
		margin-right: 20px;
	}

	.introRight {
		width: 222px;
		height: 58px;
		background: #00D0B0;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 58px;
		text-align: center;
	}

	.zhezhao2 {
		width: 222px;
		height: 58px;
		background: #000000;
		opacity: 0.6;
		position: absolute;
		z-index: 1;
		margin-top: -58px;
	}

	.allRight {
		width: 25%;
		height: 560px;
		margin-left: 5%;
		overflow: hidden;
		margin-top: 20px;
	}


	.allRightBox {
		width: 110%;
		height: 580px;
		overflow-y: auto;
	}

	.collapseItem {
		width: 92%;
		height: auto;
		overflow: hidden;
	}

	.tabsContent1 {
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.shareBox1 {
		width: 342px;
		height: 269px;
		background: #FFFFFF;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		position: absolute;
		margin-left: -700px;
		margin-top: 65px;
		display: flex;
		z-index: 300px;
		flex: 3;
		flex-wrap: nowrap;
	}

	.triangle1 {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		margin-left: -520px;
		margin-top: 60px;
	}

	.phoneBox {
		width: 342px;
		height: 300px;
		background: #FFFFFF;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		position: absolute;
		margin-left: -600px;
		margin-top: 65px;
		text-align: center;
	}

	.phoneBoxText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-top: 30px;
	}

	.phoneBoxCenter {
		width: 90%;
		height: 140px;
		margin: 0 auto;
		margin-top: 20px;
		display: flex;
	}

	.phoneBoxItem {
		width: 150px;
		height: 140px;
	}

	.diviver {
		width: 1px;
		height: 120px;
		background-color: #E4E4E4;
	}

	.itemText {
		width: 80px;
		height: auto;
		margin: 0 auto;
		margin-top: 12px;
	}

	.itemText span {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.phoneBoxBtn {
		width: 150px;
		height: 40px;
		margin: 0 auto;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 20px;
		line-height: 40px;
		text-align: center;
		background-color: #00D0B0;
		color: white;
		border-radius: 20px;
	}

	.triangle2 {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		margin-left: -440px;
		margin-top: 60px;
	}

	i {
		margin-top: 0;
	}

	.buyCarIcon {
		width: 18px;
		height: 15px;
	}

	.mr80 {
		margin-right: 80px;
	}

	.fex-speen-aroud {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: 100px;
		width: 76%;
		position: absolute;
		margin-top: 100px;
		justify-content: space-between;
	}

	.mcri-50px {
		margin-left: -50px !important;
	}

	.mcri-50rpx {
		margin-right: -50px !important;
	}

	.styletwo {
		width: 17px;
		height: 17px;
	}

	.mristyle {
		margin: 0 15px;
	}

	.mcimr34 {
		margin-right: 34px;
	}

	.mci4 {
		margin-right: 4px;
	}

	.d12 {
		width: 12px;
		height: 12px;
	}
	.p-mic-room-about-img-left {
		width: 21px;
		height: 40px;
	}
	
	.p-mic-room-about-img-right {
		width: 21px;
		height: 40px;
		z-index: 200;
	}
	.el-breadcrumb__item{
		cursor: pointer;
	}
</style>
